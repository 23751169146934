<template>
  <MainLayout>
    <div class="about">
      <h1>View your nfts here</h1>

      <FormulateForm class="form" v-model="formValues" @submit="getData">
        <FormulateInput type="submit" label="View NFTs" />
      </FormulateForm>

      <div class="nft-wrapper">
        <div class="row">
          <div class="nfts" v-for="item in images" :key="item.id">
            <div class="col-md-4">
              <div class="thumbnail">
                <img v-bind:src="item.image" alt="" srcset="" />
                <div class="name">{{ item.name }}</div>
                <!-- <div class="description">{{ item.description }}</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/views/layout/MainLayout.vue";
import {
  resolveToWalletAddress,
  getParsedNftAccountsByOwner,
} from "@nfteyez/sol-rayz";
import axios from "axios";
import { Connection } from "@solana/web3.js";
import { db } from "../../plugins/firebase";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
} from "firebase/firestore";
export default {
  // created() {
  //   if (window.solana.isConnected) {
  //     getData();
  //   } else {
  //     alert("wallet not connected");
  //   }
  // },
  components: {
    MainLayout,
  },
  data() {
    return {
      images: [],
    };
  },
  methods: {
    
    async getData() {
      if (this.images.length != 0) {
        this.image = [];
      } else {
        try {
          // const address = "3EqUrFrjgABCWAnqMYjZ36GcktiwDtFdkNYwY6C6cDzy;
          // or use Solana Domain
          var pub_keys = [];
          var address;
          const querySnapshot = await getDocs(collection(db, "PublicKey"));
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            var pub_key = {};
            pub_key.public_key = doc.data();
            pub_keys.push(pub_key.public_key);
            //console.log(doc.id, " => ", doc.data());
          });
          console.log(pub_keys[1].public_key);

          // for (var a = 0; a < pub_key.length; a++) {
          //   address = pub_key[a].public_key;
          for (var a = 0; a < pub_keys.length; a++) {
            if (window.solana.isConnected) {
              address = pub_keys[a].public_key.toString();
            } else {
              alert("Wallet not connected");
            }

            const connection = new Connection("https://api.devnet.solana.com/");
            const publicAddress = await resolveToWalletAddress({
              text: address,
            });

            var nftArray = await getParsedNftAccountsByOwner({
              publicAddress,
              connection,
            });

            for (var i = 0; i < nftArray.length; i++) {
              var uri = nftArray[i].data["uri"];
              //console.log(uri);
              await axios
                .get(uri)
                .then((response) => {
                  console.log(response.data);
                  //images = JSON.stringify(response.data["image"]);
                  //console.log(images);
                  this.images.push({
                    id: i,
                    image: response.data["image"],
                    name: response.data["name"],
                    description: response.data["description"],
                  });
                })
                .catch((error) => console.error(error));
            }
          }
        } catch (error) {
          console.log("Error thrown, while fetching NFTs", error.message);
        }
      }
    },
  },
};
</script>

<style scoped>
.form {
  max-width: 520px;
  text-align: left;
  margin: 20px;
}

img {
  object-fit: contain;
  height: auto;
  width: 240%;
  margin: 20px;
}
.nfts {
  text-align: center;
  max-width: 280px;
}
.nf-wrapper {
  display: flex;
}
</style>
